module.exports = [{
      plugin: require('../../../themes/paws/gatsby-browser.tsx'),
      options: {"plugins":[],"brandId":"539EC2E1-97BD-48C5-970F-5493D885E132","productRef":"KC5WF","allowedSpecies":["Dog"]},
    },{
      plugin: require('../../../themes/agria/gatsby-browser.tsx'),
      options: {"plugins":[],"noManifestPlugin":true},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/packages/themes/kc/src/images/icon.svg","icon_options":{"purpose":"any"},"name":"KC Insurance","short_name":"KC","start_url":"/","background_color":"#006954","theme_color":"#006954","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6a5df77059bae6772ff0227d58788af5"},
    },{
      plugin: require('../../../themes/qab/gatsby-browser.tsx'),
      options: {"plugins":[],"companyName":"The Kennel Club","contactNumber":"03330 30 83 40","footerTerms":"\n          <p>\n            For UK customers: <br />\n\n            The Royal Kennel Club Limited is an Appointed Representative of Agria Pet Insurance Ltd, who administer the insurance. Agria Pet Insurance is authorised and regulated by the Financial Conduct Authority, Financial Services Register Number 496160. Agria Pet Insurance Ltd is registered and incorporated in England and Wales with registered number 04258783. Registered office: First Floor, Blue Leanie, Walton Street, Aylesbury, Buckinghamshire, HP21 7QW. Agria insurance policies are underwritten by Agria Försäkring who is authorised and regulated by the Prudential Regulatory Authority and Financial Conduct Authority.\n          </p>\n\n          <p>\n          <br />\n          For Jersey customers: <br />\n          \n          Agria Pet Insurance Ltd is regulated by the Jersey Financial Services Commission (JFSC). Ref: 0001498. Registered office: As detailed above.\n          </p>\n          \n          <p>\n          <br />\n            For Guernsey customers: <br />\n          \n            Clegg Gifford Channel Islands Limited is licensed by the Guernsey Financial Services Commission. Ref: 2722221. Registered office: Admiral House, Place Du Commerce, St Peter Port, Guernsey GY1 2AT.\n          </p>\n        ","trustpilotUrl":"https://uk.trustpilot.com/review/www.thekennelclub.org.uk","allowedSpecies":["Dog"],"isLitter":true,"petOrAnimal":"animal","isFreeJourney":true},
    },{
      plugin: require('../../../themes/qab-affiliate-club/gatsby-browser.tsx'),
      options: {"plugins":[],"affiliateType":"breeder","affiliateTypeIds":["a04b49c9-e8ee-4477-a5bd-e40a9a446183","466385a7-c330-404f-bf6a-305a751b5eb5"],"heroHeading":"Activate 5 weeks free cover","heroDescription":"Give your litters the best start in their forever homes","numberWeeksFreeCover":"5"},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
